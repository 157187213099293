import React, { useContext } from "react";
import { NavLink } from "react-router-dom";


import "./AppHeader.scss";
import { PathAccountContext } from "../../../contexts/PathAcountContext";
import shortenAddress from "../../../utils/helpers/web3/shorten-address";
// props interface
interface AppHeaderProps {
  onHandleDisconnectWallet: () => void;
}

const AppHeader = ({
  onHandleDisconnectWallet
}: AppHeaderProps ): JSX.Element  => {

  const account = useContext(PathAccountContext);
  const handleDisconnectWallet = () => {
    onHandleDisconnectWallet();
  };
 
  return (
    <div className="header flex w-full justify-end">
      <nav className="px-2">
        <div className=" w-full md:block md:w-auto" id="navbar-multi-level">
          <ul className="flex p-4 mt-4 borderrounded-lg  md:space-x-8 md:mt-0 md:text-sm font-bold md:border-0 items-center">
            <li>
              <NavLink
                to="/"
                className="block px-4 text-[grey] rounded uppercase text-bold text-xs md:text-xl "
                aria-current="page"
              >
                HOME
              </NavLink>
            </li>

            <li>
              <NavLink
                to="collection"
                className="block px-4 text-[grey] rounded uppercase text-bold text-xs md:text-xl "
              >
                MY COLLECTION
              </NavLink>
            </li>
            <li>
              <NavLink
                to="https://opensea.io/collection/hodlzoo"
                className="block px-4 text-[grey] rounded uppercase text-bold text-xs md:text-xl "
              >
                BUY/SELL
              </NavLink>
            </li>
            <li>
              {/* account */}
              <button
                onClick={handleDisconnectWallet}
                className="py-2 pl-3 pr-4 flex bg-[#5c412c]  gap-2 text-gray text-[#fdeac9] rounded-xl uppercase text-bold text-sm md:text-xl "
              >
                <span className="text-md sm:text-xs hidden sm:block">{shortenAddress(account)}</span>
                <div className="w-4 h-4" >
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-out-alt" className="svg-inline--fa fa-sign-out-alt fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"></path></svg>
                </div>
              </button>

            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default AppHeader;
