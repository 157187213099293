import React from "react";

const Home = (): JSX.Element => {
  return (
    <>
        <div className="flex items-center justify-center w-screen">
          <div>
            <img className="object-fill" width="100%" height="100%" src={require("../assets/images/banner.png")} alt="HODLZOO banner" />
          </div>
        </div>
    </>
  );
};

export default Home;
