// @ts-ignore
import { InjectedConnector } from '@web3-react/injected-connector';
// @ts-ignore
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { CHAIN_DETAILS, CHAIN_IDS } from '../../../config/web3/chains';

const injected = new InjectedConnector({
  supportedChainIds: [
    CHAIN_IDS.BSC,
  ]
});

const walletconnect = new WalletConnectConnector({
  rpc: {
    [CHAIN_IDS.BSC]: CHAIN_DETAILS[CHAIN_IDS.BSC].rpcUrls[0],
  },
  chainId: CHAIN_IDS.BSC,
  supportedChainIds: [
    CHAIN_IDS.BSC,
  ],
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true
});
export {
  injected,
  walletconnect
};
