import MetaMask from "containers/Metamask";
import WalletConnect from "containers/WalletConnect";
import React, { useEffect, useState, Fragment, useRef } from "react";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { Dialog, Transition } from "@headlessui/react";
import shortenAddress from "utils/helpers/web3/shorten-address";
import getChainErrorMessage from "utils/helpers/web3/get-chain-error-message";
import "./WalletModal.scss";

interface IProps {
  isVisible: boolean;
  onClose: (val: boolean) => any;
  onHandleDisconnectWallet: () => void;
}
const WalletModal = (props: IProps) => {
  const [open, setOpen] = useState(true);
  const { error, account, active, deactivate, connector, chainId } =
    useWeb3React<Web3Provider>();
  const [ignoreError, setIgnoreError] = useState(false);
  const cancelButtonRef = useRef(null);
  const handleCloseModal = () => {
    setOpen(false);
    props.onClose(false);
  };
  const isAccountActive = () => {
    return account && active && connector;
  };
  const handleDeactivate = () => {
    props.onHandleDisconnectWallet();
  };
  useEffect(() => {
    if (error?.name === "UserRejectedRequestError") {
      setIgnoreError(true);
      return;
    }
    setIgnoreError(false);
  }, [error]);
  useEffect(() => {
    setOpen(props.isVisible);
  }, [props.isVisible]);
  // close window automaticaly if we correct connect to wallet
  useEffect(() => {
    if (isAccountActive()) {
      setOpen(false);
      props.onClose(false);
    }
  }, [account, active, connector]);
  
  return (
    <Transition.Root show={open} as={React.Fragment} appear>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-auto z-10"
        initialFocus={cancelButtonRef}
        onClose={() => handleCloseModal()}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed  bg-transparent inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="flex bg-transparent min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {!!error && !ignoreError ? (
              <Dialog.Panel className="relative wallet-dialog__container transform overflow-hidden rounded-lg text-left transition-all sm:my-8 sm:w-full sm:max-w-lg shadow-[0_0_15px_2px_#5c412c]">
                <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <p className="text-black text-xl">{getChainErrorMessage(error)}</p>
                </div>
              </Dialog.Panel>
            ) : (
              <Dialog.Panel className="relative wallet-dialog__container transform overflow-hidden rounded-lg text-left transition-all sm:my-8 sm:w-full sm:max-w-lg shadow-[0_0_15px_2px_#5c412c]">
                <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  {!isAccountActive() && !error ? (
                    <div className="">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="title text-base font-bold leading-6 text-[#5c412c] "
                        >
                          Connect wallet
                        </Dialog.Title>
                      </div>
                      <div className="wallet-dialog__container-buttons flex justify-center items-center">
                        <MetaMask />
                        <WalletConnect />
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="title text-base font-bold leading-6 text-[#5c412c] "
                        >
                          <span className="mr-2">Disconnect wallet: </span>
                          <span className="whitespace-nowrap">
                            {shortenAddress(account)}
                          </span>
                        </Dialog.Title>
                      </div>

                      <div className="wallet-dialog__container-buttons flex justify-center items-center">
                        <button
                          type="button"
                          className="inline-flex justify-center w-full m-4 px-4 py-2 text-sm font-medium text-white bg-[#5c412c] border border-transparent rounded-md shadow-sm hover:bg-[#5c412c] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5c412c] sm:text-sm"
                          onClick={() => {
                            handleDeactivate();
                          }}
                        >
                          Disconnect wallet
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            )}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default WalletModal;
