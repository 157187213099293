
import axios from 'axios';

class API {
    static post(url: string, data: any) {
        return axios.post(url, data);
    }
    static get(url: string) {
        return axios.get(url);
    }
    static getNFTDetails(id: number) {
        // return this.get(`https://api.hodlzoo.com/nft/${id}`);
        return this.get(process.env.REACT_APP_API_URL + '/nft/' + id);
    }
}
export default API;