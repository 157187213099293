import React from "react";


const BuySell: React.FC = (): JSX.Element => {
  return (
    <>
      <div className="container mx-4 my-4 h-screen">
       <span>Buy and Sell NFT</span>
      </div>
    </>
  );
};

export default BuySell;
