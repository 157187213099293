import { isAddress } from '@ethersproject/address';

const shortenAddress = (address: string | null | undefined) : string => {
    if (!address || address === undefined) return '';
  const valid = isAddress(address);
  if (!valid) {
    throw Error(`Invalid 'address' parameter '${address}'!`);
  }
  return address?.length > 8 ? `${address.slice(0, 4)}…${address.slice(-3)}` : address;
};

export default shortenAddress;