import React from "react";
// @ts-ignore
import TelegramLogo from "../../assets/icons/telegram.svg";
// @ts-ignore
import TwitterLogo from "../../assets/icons/twitter.svg";
// @ts-ignore
import BinanceLogo from "../../assets/icons/bnb-logo.svg";
import { goToLink } from "../../utils/common";
const AppFooter: React.FC = () => {
  return (
    <div className="flex w-full justify-center self-end mb-4">
      <div className="socials-icon h-8 w-8 flex items-center justify-center" onClick={goToLink('binance')}>
        <img src={BinanceLogo} width="20" alt="binance icon" />
      </div>
      <div className="socials-icon h-8 w-8 flex items-center justify-center" onClick={goToLink('telegram')}>
        <img src={TelegramLogo} width="20" alt="telegram" />
      </div>
      <div className="socials-icon h-8 w-8 flex items-center justify-center" onClick={goToLink('twitter')} >
        <img src={TwitterLogo} width="20" alt="twitter icon" />
      </div>
    </div>
  );
};

export default AppFooter;
