import React from "react";
import "./LendFooter.scss";

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <div className="footer-line"></div>
    </div>
  );
};

export default Footer;
