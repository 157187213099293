import React from 'react';
// @ts-ignore
import TelegramLogo from '../../assets/icons/telegram.svg';
// @ts-ignore
import TwitterLogo from '../../assets/icons/twitter.svg';
// @ts-ignore
import BinanceLogo from '../../assets/icons/bnb-logo.svg';
import { goToLink } from '../../utils/common';
const Header: React.FC = () => {
  return (
    <div className="header w-full p-8">
      <nav className="flex items-center justify-between">
        <a href="https://hodlzoo.com/" className="flex items-center w-48">
          <img className="logo-image" alt="HODLZOOlogo" src={require('../../assets/HODLZOO.png')} />
        </a>
        <div className="flex mr-4 " id="navbar-default">
          <div className="m-3 socials-icon h-8 w-8 flex items-center justify-center" onClick={goToLink('binance')}>
            <img src={BinanceLogo} width="25" alt="binance icon" />
          </div>
          <div className="m-3 socials-icon h-8 w-8 flex items-center justify-center" onClick={goToLink('telegram')}>
            <img src={TelegramLogo} width="25" alt="telegram" />
          </div>
          <div className="m-3 socials-icon h-8 w-8 flex items-center justify-center" onClick={goToLink('twitter')}>
            <img src={TwitterLogo} width="25" alt="twitter icon" />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
