
import { CHAIN_DETAILS, CHAIN_IDS } from "config/web3/chains";
import { StaticJsonRpcProvider } from "@ethersproject/providers";
import HodlZooAbi from "../abis/contracts/hodlzoo.json"; // Makes the Smart Contracts ABI available
import { Contract } from "@ethersproject/contracts";

const newNFTContract = (address: string): Contract => {
    return new Contract(
      address,
      HodlZooAbi,
      new StaticJsonRpcProvider(CHAIN_DETAILS[CHAIN_IDS.BSC].rpcUrls[0])
    );
  };


export {
    newNFTContract
}