import React, { useEffect } from "react";
import clsx from "clsx";
import { Contract } from "@ethersproject/contracts";
import { BigNumber } from "ethers";
import { getAddress } from "ethers/lib/utils";
import { nftContract as nftContractAddress } from "../config/web3/contracts";
import API from "api/api";
import { debounce } from "lodash";
import { useWeb3React } from "@web3-react/core";
import { newNFTContract } from "hodl-router";
import { TwitterShareButton, TwitterIcon } from "react-share";
const Collection: React.FC = (): JSX.Element => {
  interface nftURIs {
    image: string;
    name: string;
    description: string;
    attributes: Attribute[];
  }

  interface Attribute {
    trait_type: string;
    value: string;
  }
  const { account } = useWeb3React();
  const [nftIDs, setNftIDs] = React.useState<number[]>([]);
  const [nftContract, setNftContract] = React.useState<Contract>();
  const [existingNFTDetails, setExistingNFTDetails] = React.useState<nftURIs[]>(
    []
  );
  const [contentLoaded, setContentLoaded] = React.useState<boolean>(false);
  const [nftDetails, setnftDetails] = React.useState<nftURIs>({
    image: "",
    name: "",
    description: "",
    attributes: [],
  });

  const selectCollectionItem = (item: nftURIs) => {
    setnftDetails(item);
  };

  const cleanData = () => {
    setNftIDs([]);
    setExistingNFTDetails([]);
  };

  const initContract = async () => {
    try {
      setContentLoaded(false);
      const nftContract = newNFTContract(nftContractAddress);
      setNftContract(nftContract);
      cleanData();
      if (!nftContract || !account) return;
      const ownerAddress = getAddress(account);
      const res = await nftContract.callStatic.balanceOf(ownerAddress);
      const amountOfNfts = parseInt(BigNumber.from(res).toString());
      if (amountOfNfts > 0) {
        const ids = new Set<number>();
        for (let i = 0; i < amountOfNfts; i++) {
          const res = await nftContract.callStatic.tokenOfOwnerByIndex(
            ownerAddress,
            i
          );
          const nftID = parseInt(BigNumber.from(res).toString());
          ids.add(nftID);
        }
        setNftIDs([...ids]);
      } else {
        setContentLoaded(true);
        return;
      }
    } catch (error) {
      console.log(error);
      setContentLoaded(true);
    }
  };

  const debouncedFetchNFTDetails = React.useMemo(() => {
    return debounce(async () => {
      try {
        if (!nftContract) return;
        const nftDetails = [];
        for (const id of nftIDs) {
          const response = await API.getNFTDetails(id);
          if (response.status === 200) {
            nftDetails.push(response.data);
          }
        }
        setExistingNFTDetails(nftDetails);
        if (nftDetails.length > 0) {
          setnftDetails(nftDetails[0]);
          setContentLoaded(true);
        }
      } catch (error) {
        console.error(error);
        setContentLoaded(true);
      }
    }, 500); // set the debounce delay to 500ms
  }, [nftContract, nftIDs]);

  useEffect(() => {
    debouncedFetchNFTDetails();
  }, [nftContract, nftIDs, debouncedFetchNFTDetails]);
  // get data on page load
  useEffect(() => {
    const init = async () => {
      await initContract();
    };
    init();
  }, [account]);
  return (
    <>
      {!contentLoaded && existingNFTDetails.length === 0 && (
        <div className="w-full">
          <div className="mb-4">
            <div role="status" className="max-w-sm animate-pulse">
              <div className="h-10 bg-[#826956] rounded-full dark:bg-[#efc4a3] w-48 mb-4"></div>
            </div>
            <div className="ml-4 animate-pulse">
              <div className="h-2.5 bg-[#826956] rounded-full dark:bg-[#efc4a3] w-32 mb-4"></div>
            </div>
          </div>
          <div className="grid grid-rows-1 grid-flow-row gap-4 sm:grid-flow-col mb-8">
            <div
              role="status"
              className="space-y-8 row-span-3 object-cover max-w-[800px] animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center"
            >
              <div className="flex items-center justify-center w-full h-48 min-h-[350px] rounded sm:w-96 ">
                <svg
                  className="w-12 h-12 text-[#826956]"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 640 512"
                >
                  <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                </svg>
              </div>
            </div>
            <div
              role="status"
              className="row-span-3 space-y-8 animate-pulse flex items-center flex-col justify-center h-full p-2 text-[gray]"
            >
              <div className="w-full max-w-[350px]">
                <div className="h-2 bg-[#826956] mb-2 rounded-full dark:bg-gray-700 max-w-[350px]"></div>
                <div className="h-2.5 bg-[#826956] rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              </div>
            </div>
          </div>
          <div
            role="status"
            className=" flex items-center justify-center w-100 sm:flex-col space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center mb-4"
          >
            <div className="w-full max-w-[350px]">
              <div className="h-2.5 bg-[#826956] rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2 bg-[#826956] rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
              <div className="h-2 bg-[#826956] rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-[#826956] rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
              <div className="h-2 bg-[#826956] rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
              <div className="h-2 bg-[#826956] rounded-full dark:bg-gray-700 max-w-[360px]"></div>
            </div>
          </div>
        </div>
      )}
      {contentLoaded && existingNFTDetails.length > 0 && (
        <div className={clsx("w-full")}>
          <div className="mb-4">
            <div className="uppercase text-5xl font-black text-[#5c412c] mb-4">
              {nftDetails.name}
            </div>
            <div className="flex items-center gap-2">
              <p className="uppercase text-[#5c412c]">share now:</p>
              <TwitterShareButton
                url={nftDetails.image}
                title={nftDetails.name}
              >
                <div className="flex items-center gap-1 text-[#07abec]">
                  <TwitterIcon size={18} round />
                  <p className="uppercase text-sm">twitter</p>
                </div>
              </TwitterShareButton>
            </div>
          </div>
          <div className="grid grid-rows-1 grid-cols-1 sm:grid-cols-2 grid-flow-row gap-4 sm:grid-flow-col mb-8 transition-opacity ease-in duration-700 opacity-1">
            {nftDetails.image && (<div className="row-span-3 grid-cols-6 object-cover max-w-[800px] min-w-[350px] ">
              <img className={clsx("rounded-lg")} src={nftDetails.image} alt="Collection image preview" />
            </div>)}
            <div className="grid-cols-6 row-span-3 flex items-start lg:items-center flex-col justify-center h-full p-2 text-[gray] ">
              <div className="p-4 bg-[#fdeac9] text-[#5c412c] rounded-lg text-md md:text-lg lg:text-xl font-bold">
                {nftDetails.attributes.map((item: any) => (
                  <div className="flex items-center gap-2" key={item.name}>
                    <p className="uppercase">{item.trait_type}:</p>
                    <p className="uppercase">{item.value}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* slider */}
          <div className="uppercase text-center font-bold text-[#5c412c] text-xl mb-2">
            My Collection
          </div>
          <div className="flex w-full flex-wrap items-center justify-center">
            {(existingNFTDetails || []).map((item: nftURIs) => (
              <div className=" text-[black] p-2 " key={item.name}>
                <img
                  className={clsx(
                    "border-[3px]",
                    item.name !== nftDetails.name && ["filter", "grayscale"],
                    "transition-all",
                    "duration-300",
                    "ease-in-out",
                    "border-[#5c412c]",
                    "object-fill",
                    "h-24",
                    "w-24"
                  )}
                  src={item.image}
                  alt="Collection image preview"
                  onClick={() => selectCollectionItem(item)}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {contentLoaded && existingNFTDetails.length === 0 && (
        <div className="w-full h-screen flex justify-center flex-col ">
          <div className="uppercase text-xl sm:text-2xl md:text-4xl  font-black text-[#5c412c] text-center mb-4">
            You dont have NFT in your wallet
          </div>
          <a
            className="text-[#5c412c] underline text-center text-md self-center"
            href="https://opensea.io/collection/hodlzoo"
          >
            Looks for your RHINO on OpenSea
          </a>
        </div>
      )}
    </>
  );
};

export default Collection;
