import React from "react";


const NotFound: React.FC = (): JSX.Element => {
  return (
    <>
      <div className="container mx-4 my-4">
       <span>404 page</span>
      </div>
    </>
  );
};

export default NotFound;
