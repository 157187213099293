import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import clsx from "clsx";
import { PathAccountContext } from "./contexts/PathAcountContext";
import WalletModal from "./components/Modals/WalletModal/WalletModal";
import Header from "./components/UI/LendHeader";
import Footer from "./components/UI/LendFooter/LendFooter";
import Home from "./pages/Home";
import Collection from "./pages/Collection";
import BuySell from "./pages/BuySell";
import NotFound from "./pages/NotFound";
import AppHeader from "./components/UI/AppHeader/AppHeader";
import AppFooter from "./components/UI/AppFooter";
import "./App.scss";
window.Buffer = window.Buffer || require("buffer").Buffer;

const App = (): JSX.Element | null => {
  const { account, connector, deactivate } = useWeb3React<Web3Provider>();
  const [showWalletModal, setShowWalletModal] = useState<boolean>(false);
  const handleDisconnectWallet = () => {
    handleDeactivate();
  };
  const handleDeactivate = () => {
    if (!connector) {
      return;
    }
    try {
      (connector as any).close();
      return;
    } catch (e) {
      // Do nothing
    }
    try {
      deactivate();
    } catch (e) {
      // Do nothing
    }
  };


  return (
    <>
      <div className={clsx("relative", "container", "min-h-screen", "min-w-full","flex","flex-col","items-center")}>

        {!account ? (
          <>
            <Header />
            <div className="w-full h-full justify-center flex flex-col items-center">
              <div className="content info flex flex-col items-center justify-center text-center p-2">
                <h1 className="info-title">HODLZOO</h1>
                <span className="info-subtitle">
                  collection of randomly generated NFTs,
                </span>
                <span className="info-subtitle">
                  each with its own style, color and features.
                </span>
                <div className="buttons flex items-center justify-center">
                <button
                  onClick={() => setShowWalletModal(true)}
                  className="
                connect-wallet__button
                flex
                flex-col
                justify-center
                w-100
                items-center
                p-4 
                "
                >
                  <span>CONNECT WALLET</span>
                </button>
              </div>
              </div>
              
            </div>
            <Footer />
          </>
            ) : (
          <>
            <PathAccountContext.Provider value={`${account}`}>
              <div className="container ">
                <AppHeader onHandleDisconnectWallet={handleDisconnectWallet} />
                <div className="h-full flex items-center p-4">
                  {/* @ts-ignore */}
                  <Routes>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/collection" element={<Collection />}></Route>
                    <Route path="/buysell" element={<BuySell />}></Route>
                    <Route path='*' element={<NotFound />}/>
                  </Routes>
                </div>
                <AppFooter />
              </div>
            </PathAccountContext.Provider>
          </>
        )}
        
        <WalletModal isVisible={showWalletModal} onClose={(val) => setShowWalletModal(val)} onHandleDisconnectWallet={handleDisconnectWallet}/>
    </div>
    </>
  );
};

export default App;
