import { BigNumber } from 'ethers';

const POLLING_INTERVAL = 2000;

const CHAIN_IDS = Object.freeze({
  BSC: 56,
});

const CHAIN_ICON_PATHS = {
  [CHAIN_IDS.BSC]: '/assets/icons/bnb-logo.svg',
};

interface ChainDetails {
  chainId: string
  chainName: string
  nativeCurrency: {
    name: string
    symbol: string
    decimals: number
  }
  minimumTxAmount?: BigNumber
  defaultBlocksPerSecond?: BigNumber
  rpcUrls: string[]
  blockExplorerUrls: string[]
}

const CHAIN_DETAILS: {
  // TODO: should type correctly
  [chainId: number]: ChainDetails
} = {
  [CHAIN_IDS.BSC]: {
    chainId: '0x38',
    chainName: 'BNB Chain',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18
    },
    minimumTxAmount: BigNumber.from(10).pow(16),
    defaultBlocksPerSecond: BigNumber.from(10).pow(18).div(3),
    rpcUrls: ['https://bsc-dataseed.binance.org'],
    blockExplorerUrls: ['https://bscscan.com']
  }
};

const CHAIN_LABELS: { [chainId: number]: string } = {
  [CHAIN_IDS.BSC]: 'BNB\xa0Chain',
};

const ACTIVE_CHAINS = [
  CHAIN_IDS.BSC,
];

export {
  CHAIN_IDS,
  POLLING_INTERVAL,
  CHAIN_ICON_PATHS,
  CHAIN_DETAILS,
  CHAIN_LABELS,
  ACTIVE_CHAINS
};
