import * as React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
// import { Provider } from 'react-redux';
import { Web3ReactProvider } from "@web3-react/core";
import getLibrary from "./utils/helpers/web3/get-library";
import App from "./App";
// import store from './store';
import reportWebVitals from "./reportWebVitals";
import "./index.scss";

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      {/* <Provider store={store}> */}
      <Router>
        <App />
      </Router>
      {/* </Provider> */}
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
