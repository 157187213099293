export const goToLink = (link: string) => () => {
    switch(link) {
      case 'twitter':
        window.open(`https://twitter.com/HodlZoo`, "_blank");
        break;
      case 'binance':
        window.open(`https://bscscan.com/token/0x05a602a3c4208eaac496f8485a8bb7276bb70e64`, "_blank");
        break;
      case 'telegram':
        window.open(`https://t.me/HODLZOO`, "_blank");
        break;
    }
  };